import React from 'react'
import styled from 'styled-components'
import ResizeObserver from 'resize-observer-polyfill'

// Particle animation
class Bubble {
  public translateX = 0
  public translateY = 0
  public posX = 0
  public posY = 0
  public color: string | undefined = undefined
  public alpha: number | undefined = undefined
  public size: number | undefined = undefined

  private parentNode: Node
  private mouseX = 0
  private mouseY = 0
  private canvasWidth = 0
  private canvasHeight = 0

  private movementX = 0
  private movementY = 0
  private magnetism = 0

  private velocity = 150 // Bubble levitation velocity (the higher the slower)
  private smoothFactor = 50 // The higher, the smoother
  private staticity = 50 // Increase value to make bubbles move slower on mousemove

  private colors = ['255, 255, 255']
  // private colors = ['85,107,139', '38,141,247', '66,52,248', '255,108,80', '243, 244, 255', '96, 100, 131']

  constructor(parentNode: Node) {
    this.parentNode = parentNode
    this.getCanvasSize()
    window.addEventListener('resize', () => {
      this.getCanvasSize()
    })
    window.addEventListener('mousemove', (e: MouseEvent) => {
      this.mouseX = e.clientX
      this.mouseY = e.clientY
    })
    this.randomize()
  }

  getCanvasSize() {
    this.canvasWidth = (this.parentNode as any).clientWidth
    this.canvasHeight = document.body.scrollHeight // this.parentNode.clientHeight
  }

  generateDecimalBetween(min: number, max: number) {
    return parseFloat((Math.random() * (min - max) + max).toFixed(2))
  }

  update() {
    this.translateX = this.translateX - this.movementX
    this.translateY = this.translateY - this.movementY
    this.posX += (this.mouseX / (this.staticity / this.magnetism) - this.posX) / this.smoothFactor
    this.posY += (this.mouseY / (this.staticity / this.magnetism) - this.posY) / this.smoothFactor

    if (
      this.translateY + this.posY < 0 ||
      this.translateX + this.posX < 0 ||
      this.translateX + this.posX > this.canvasWidth
    ) {
      this.randomize()
      this.translateY = this.canvasHeight
    }
  }

  randomize() {
    this.magnetism = 0.1 + Math.random() * 4
    this.color = this.colors[Math.floor(Math.random() * this.colors.length)]
    this.alpha = this.generateDecimalBetween(5, 10) / 10
    this.size = this.generateDecimalBetween(1, 4)
    this.posX = 0
    this.posY = 0
    this.movementX = this.generateDecimalBetween(-2, 2) / this.velocity
    this.movementY = this.generateDecimalBetween(1, 20) / this.velocity
    this.translateX = this.generateDecimalBetween(0, this.canvasWidth)
    this.translateY = this.generateDecimalBetween(0, this.canvasHeight)
  }
}

class ParticlesBackground {
  private canvas: HTMLCanvasElement
  private ctx: CanvasRenderingContext2D
  private dpr: number
  private bubblesList: Bubble[] = []
  private container: Node | undefined

  constructor(canvas: HTMLCanvasElement, blur: string | undefined = undefined) {
    this.canvas = canvas
    const context = this.canvas.getContext('2d')
    if (context === null) {
      throw new Error('Failed to get rendering context for canvas')
    }
    this.ctx = context
    this.dpr = window.devicePixelRatio

    context.filter = `blur(${blur})`
  }

  start(animate: boolean) {
    this.canvasSize()
    window.addEventListener('resize', () => {
      this.canvasSize()
      this.renderFrame()
    })
    this.bubblesList = []
    this.generateBubbles()

    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    if (!animate || isMobileDevice) {
      this.renderFrame()
    } else {
      this.animate()
    }
  }

  private h = 0
  private w = 0
  private wdpi = 0
  private hdpi = 0

  canvasSize() {
    this.container = this.canvas.parentNode ?? undefined
    // Determine window width and height
    this.w = (this.container as any)?.offsetWidth ?? 0
    this.h = document.body.scrollHeight // this.container.offsetHeight
    this.wdpi = this.w * this.dpr
    this.hdpi = this.h * this.dpr
    // Set canvas width and height
    this.canvas.width = this.wdpi
    this.canvas.height = this.hdpi
    // Set width and height attributes
    this.canvas.style.width = this.w + 'px'
    this.canvas.style.height = this.h + 'px'
    // Scale down canvas
    this.ctx.scale(this.dpr, this.dpr)
  }

  renderFrame() {
    this.ctx.clearRect(0, 0, this.canvas.clientWidth, this.canvas.clientHeight)
    this.bubblesList.forEach((bubble: Bubble) => {
      bubble.update()
      this.ctx.translate(bubble.translateX, bubble.translateY)
      this.ctx.beginPath()
      this.ctx.arc(bubble.posX, bubble.posY, bubble.size ?? 0, 0, 2 * Math.PI)
      this.ctx.fillStyle = 'rgba(' + bubble.color + ',' + bubble.alpha + ')'
      this.ctx.fill()
      this.ctx.setTransform(this.dpr, 0, 0, this.dpr, 0, 0)
    })
  }

  animate() {
    this.renderFrame()
    /* global requestAnimationFrame */
    requestAnimationFrame(this.animate.bind(this))
  }

  addBubble(bubble: Bubble) {
    return this.bubblesList.push(bubble)
  }

  generateBubbles() {
    const container = this.canvas.parentNode ?? undefined
    if (container === undefined) {
      throw new Error('Undefined container')
    }
    for (let i = 0; i < this.bubbleDensity(); i++) {
      this.addBubble(new Bubble(container))
    }
  }

  bubbleDensity() {
    return 200
  }
}

// (window as any).requestAnimFrame = (() => {
//   return (
//     (window as any).requestAnimationFrame ||
//     (window as any).webkitRequestAnimationFrame ||
//     (window as any).mozRequestAnimationFrame ||
//     (window as any).oRequestAnimationFrame ||
//     (window as any).msRequestAnimationFrame ||
//     function(callback: any) {
//       window.setTimeout(callback, 1000 / 60)
//     }
//   )
// })()

const ParticlesContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  opacity: 0.8;
`

const ParticlesCanvas = styled.canvas``

export default class Particles extends React.Component {
  private _canvas: HTMLCanvasElement | undefined
  private _container: HTMLElement | undefined
  private resizeObserver: ResizeObserver | undefined

  componentDidMount() {
    if (this._canvas === undefined || this._container === undefined) {
      throw new Error('Particles elements unexpectedly undefined')
    }

    const particles = new ParticlesBackground(this._canvas)

    this.resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      particles.start(false)
    })

    this.resizeObserver.observe(this._container)
  }

  componentWillUnmount() {
    if (this._container !== undefined) {
      this.resizeObserver?.unobserve(this._container)
    }
    this.resizeObserver?.disconnect()
  }

  render() {
    return (
      <ParticlesContainer ref={el => (this._container = el ?? undefined)}>
        <ParticlesCanvas ref={el => (this._canvas = el ?? undefined)} />
      </ParticlesContainer>
    )
  }
}
