import { ButtonLight, ButtonConfirmed, ButtonError } from '../../components/Button'
import React, { useState, useEffect } from 'react'
import Modal from '../Modal'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import DisclaimerMarkdown from './disclaimer.md'
import { useActiveWeb3React } from '../../hooks'
import { useWalletModalToggle } from 'state/application/hooks'
import { ethers } from 'ethers'

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  width: 100%;
`

const DisclaimerContent = styled.div`
  max-height: 80vh;
  overflow: scroll;
  color: ${({ theme }) => theme.text2};
  background: ${({ theme }) => theme.bg2};
  padding: 1rem;
  -webkit-overflow-scrolling: auto;
  border-radius: 4px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.5);
    // -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`

const disclaimerSectionStyle = styled.div`
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 900;
`

const Heading = styled.div`
  font-size: 1.5rem;
  font-weight: 900;
  text-align: center;
`

const Info = styled.div`
  color: ${({ theme }) => theme.text4};
  font-size: 0.85rem;
  text-align: center;
`

export default function DisclaimerModal() {
  const { account, library } = useActiveWeb3React()
  const [markdown, setMarkdown] = useState('')
  const [messageToSign, setMessageToSign] = useState('')
  const [error, setError] = useState<boolean>(false)
  const [disclaimerIsOpen, setDisclaimerIsOpen] = useState<boolean>(false)
  const toggleWalletModal = useWalletModalToggle()

  useEffect(() => {
    if (!library) {
      setDisclaimerIsOpen(false)
      return
    }

    if (!account) {
      setDisclaimerIsOpen(true)
      return
    }

    async function fetchSignatureAPI(account: string) {
      const response = await fetch(
        'https://api.swapswap.org/legal-signature?' + new URLSearchParams({ address: account })
      )
      const storedSignature = await response.json()
      const signature = storedSignature['signature']
      if (!signature) {
        setDisclaimerIsOpen(true)
        return
      }
      const messageSigner = ethers.utils.verifyMessage(messageToSign, signature)
      const isValidSignature = messageSigner === account
      setDisclaimerIsOpen(!isValidSignature)
    }
    fetchSignatureAPI(account)
  }, [account, library, messageToSign])

  function onSigned() {
    setDisclaimerIsOpen(false)
  }

  function onDismiss() {
    return
  }

  useEffect(() => {
    fetch(DisclaimerMarkdown)
      .then(res => res.text())
      .then(text => {
        setMarkdown(text)
        const prefix = 'I confirm that I have read and accept the following terms of use and disclaimers:\n\n'.toUpperCase()
        setMessageToSign(`${prefix}${text}`)
      })
  }, [])

  async function sign(message: string, account: string) {
    if (!library) {
      setError(true)
      return
    }

    const signer = library.getSigner(account)
    const signature = await signer.signMessage(message)

    // localStorage.setItem(signatureStoreIndex(account), signature)

    // TODO: Store signature in backend database
    await fetch('https://api.swapswap.org/legal-signature', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        address: account,
        signature: signature
      })
    })

    onSigned()
  }

  return (
    <Modal isOpen={disclaimerIsOpen} onDismiss={onDismiss} maxHeight={90}>
      <ModalContent>
        <Heading>Terms of Use</Heading>

        <DisclaimerContent>
          <ReactMarkdown
            skipHtml={true}
            allowedElements={['h1', 'p', 'a', 'h2', 'h3']}
            components={{
              h1: disclaimerSectionStyle
            }}
          >
            {markdown}
          </ReactMarkdown>
        </DisclaimerContent>

        <Info>To use SwapSwap you must carefully read and accept our terms of use by signing the above text.</Info>
        {!account ? (
          <ButtonLight onClick={toggleWalletModal}>Connect a Wallet to Sign</ButtonLight>
        ) : error ? (
          <ButtonError error={true} onClick={() => window.location.reload()}>
            Error! Reload page
          </ButtonError>
        ) : (
          <ButtonConfirmed onClick={() => sign(messageToSign, account)}>Accept & Sign</ButtonConfirmed>
        )}
      </ModalContent>
    </Modal>
  )
}
